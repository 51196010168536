//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import SecondOpinionBanner from "./Banner";
import { alertTriangleIcon } from "@debionetwork/ui-icons";
import { isWeb3Injected } from "@polkadot/extension-dapp";
import { queryOpinionRequestorByOwner, queryOpinionRequestor } from "@/common/lib/polkadot-provider/query/opinion-requestor";
import { queryElectronicMedicalRecordById } from "@debionetwork/polkadot-provider";
import getEnv from "@/common/lib/utils/env";
export default {
  name: "SecondOpinion",
  components: {
    SecondOpinionBanner
  },
  data: () => ({
    headers: [{
      text: "Category",
      value: "category",
      sortable: true
    }, {
      text: "Symptom Description",
      value: "description",
      sortable: true
    }, {
      text: "Granted Health Record",
      value: "grantedPHR",
      sortable: true
    }, {
      text: "Opinion Available",
      value: "opinionAvailable",
      sortable: true
    }, {
      text: "Action",
      value: "action",
      sortable: true
    }],
    items: [],
    isNotInstalled: false,
    alertTriangleIcon,
    isLoading: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  async mounted() {
    await this.fetchSecondOpinionData();
  },
  methods: {
    async toRequest() {
      this.isNotInstalled = !isWeb3Injected;
      if (!this.isNotInstalled) {
        this.$router.push({
          name: "second-opinion-request"
        });
      }
    },
    async toMyriad(id) {
      window.open("".concat(getEnv("VUE_APP_MYRIAD_URL"), "/login?redirect=").concat(getEnv("VUE_APP_MYRIAD_URL"), "%2Fpost%2F").concat(id), "_blank");
    },
    async toInstall() {
      window.open("https://polkadot.js.org/extension/", "_blank");
    },
    async fetchSecondOpinionData() {
      const data = await queryOpinionRequestorByOwner(this.api, this.wallet.address);
      for (let i = 0; i < data.length; i++) {
        const item = await queryOpinionRequestor(this.api, data[i]);
        const electronicMedicalRecordDetails = [];
        const electronicMedicalRecordIds = item.info.electronicMedicalRecordIds;
        for (let j = 0; j < electronicMedicalRecordIds.length; j++) {
          const detail = await queryElectronicMedicalRecordById(this.api, electronicMedicalRecordIds[j]);
          electronicMedicalRecordDetails.push(detail);
        }
        this.items.unshift({
          ...item,
          electronicMedicalRecordDetails
        });
      }
    }
  }
};