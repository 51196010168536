//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SecondOpinionBanner"
};