var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"second-opinion"},[_c('div',{staticClass:"second-opinion__wrapper"},[_c('SecondOpinionBanner'),_c('ui-debio-button',{staticClass:"second-opinion__button",attrs:{"color":"#FF8EF4","dark":""},on:{"click":_vm.toRequest}},[_vm._v("+ Request Second Opinion")]),_c('ui-debio-data-table',{staticClass:"second-opinion__table",attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.items},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column second-opinion__table-headers-category"},[_c('span',[_vm._v(_vm._s(item.info.category))])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column second-opinion__table-headers-description"},[_c('span',[_vm._v(_vm._s(item.info.description))])])]}},{key:"item.grantedPHR",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"second-opinion__table-headers-PHR"},_vm._l((item.electronicMedicalRecordDetails),function(grantedPHR,idx){return _c('div',{staticClass:"second-opinion__table-headers-PHR-content"},[_c('v-alert',{staticClass:"second-opinion__table-alert",attrs:{"color":"#F9F5FF"}},[_c('span',{staticClass:"second-opinion__table-alert-text"},[_vm._v(_vm._s(grantedPHR.title))])])],1)}),0)]}},{key:"item.opinionAvailable",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column second-opinion__table-headers-opinion"}),_c('span',[_vm._v(_vm._s(item.info.opinionIds.length))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ui-debio-button',{staticClass:"second-opinion__table-button",staticStyle:{"font-size":"6px"},attrs:{"color":"#FF8EF4","dark":"","text":"","width":"120px","height":"35"},on:{"click":function($event){return _vm.toMyriad(item.info.myriadPostId)}}},[_vm._v("Visit My Request")])]}}],null,true)},[_c('template',{slot:"prepend"},[_c('div',{staticClass:"second-opinion__table-title"},[_vm._v("Requested Opinion")]),_c('div',{staticClass:"second-opinion__table-description"},[_vm._v("List of My Requested Opinion(s)")])])],2),_c('ui-debio-modal',{attrs:{"show":_vm.isNotInstalled,"show-title":false,"show-cta":false},on:{"onClose":function($event){_vm.isNotInstalled = false}}},[_c('h2',{staticClass:"mt-5"},[_vm._v("Install Polkadot Extension")]),_c('ui-debio-icon',{staticClass:"mb-8",attrs:{"icon":_vm.alertTriangleIcon,"stroke":"","size":"80"}}),_c('p',{staticClass:"second-opinion__modal-subtitle"},[_vm._v("Press install Polkadot Extensions button below to continue, your second opinion will be redirected to myriad.social platform.")]),_c('ui-debio-button',{attrs:{"block":"","color":"secondary"},on:{"click":_vm.toInstall}},[_vm._v("Install")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }